import React, { Fragment } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import Layout from "../components/layout/layout";
import { graphql, Link } from "gatsby";
import Fold from "../components/Fold/Fold";
import Content from "../components/Content/Content";
import { Heading } from "../components/Heading/Heading";
import Obfuscate from "react-obfuscate";

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  return (
    <Layout
      pageTitle={t("global.privacy-policy")}
      description={t("privacy-policy.status")}
      narrowLayout
    >
      <Fold title={t("global.privacy-policy")} centered></Fold>
      <Content>
        <p className="note">{t("privacy-policy.note")}</p>

        <Heading level="h2" smallDecorationDown>
          {t("privacy-policy.1-definition-of-terms.title")}
        </Heading>

        <p>{t("privacy-policy.1-definition-of-terms.a.title")}</p>
        <p>{t("privacy-policy.1-definition-of-terms.a.desc")}</p>

        <p>{t("privacy-policy.1-definition-of-terms.b.title")}</p>
        <p>{t("privacy-policy.1-definition-of-terms.b.desc")}</p>

        <p>{t("privacy-policy.1-definition-of-terms.c.title")}</p>
        <p>{t("privacy-policy.1-definition-of-terms.c.desc")}</p>

        <p>{t("privacy-policy.1-definition-of-terms.d.title")}</p>
        <p>{t("privacy-policy.1-definition-of-terms.d.desc")}</p>

        <p>{t("privacy-policy.1-definition-of-terms.e.title")}</p>
        <p>{t("privacy-policy.1-definition-of-terms.e.desc")}</p>

        <p>{t("privacy-policy.1-definition-of-terms.f.title")}</p>
        <p>{t("privacy-policy.1-definition-of-terms.f.desc")}</p>

        <p>{t("privacy-policy.1-definition-of-terms.g.title")}</p>
        <p>{t("privacy-policy.1-definition-of-terms.g.desc")}</p>

        <p>{t("privacy-policy.1-definition-of-terms.h.title")}</p>
        <p>{t("privacy-policy.1-definition-of-terms.h.desc")}</p>

        <p>{t("privacy-policy.1-definition-of-terms.i.title")}</p>
        <p>{t("privacy-policy.1-definition-of-terms.i.desc")}</p>

        <p>{t("privacy-policy.1-definition-of-terms.j.title")}</p>
        <p>{t("privacy-policy.1-definition-of-terms.j.desc")}</p>

        <p>{t("privacy-policy.1-definition-of-terms.k.title")}</p>
        <p>{t("privacy-policy.1-definition-of-terms.k.desc")}</p>

        <Heading level="h2" smallDecorationDown>
          {t("privacy-policy.2-name-and-address.title")}
        </Heading>

        <p>{t("privacy-policy.2-name-and-address.desc")}</p>

        <ul className="list--no-bullets">
          <li>IQC-Group AG</li>
          <li><a href="tel:+41445009090">+41 44 500 90 90</a></li>
          <li> <Obfuscate className="link" email="info@iqc-group.com" /></li>
        </ul>

        <Heading level="h2" smallDecorationDown>
          {t("privacy-policy.3-cookies.title")}
        </Heading>

        <p>{t("privacy-policy.3-cookies.text1")}</p>
        <p>{t("privacy-policy.3-cookies.text2")}</p>
        <p>{t("privacy-policy.3-cookies.text3")}</p>

        <Heading level="h2" smallDecorationDown>
          {t("privacy-policy.4-collection.title")}
        </Heading>

        <p>{t("privacy-policy.4-collection.intro")}:</p>
        <ul className="list--no-bullets">
          <li>{t("privacy-policy.4-collection.recorded.item1")}</li>
          <li>{t("privacy-policy.4-collection.recorded.item2")}</li>
          <li>{t("privacy-policy.4-collection.recorded.item3")}</li>
          <li>{t("privacy-policy.4-collection.recorded.item4")}</li>
          <li>{t("privacy-policy.4-collection.recorded.item5")}</li>
          <li>{t("privacy-policy.4-collection.recorded.item6")}</li>
          <li>{t("privacy-policy.4-collection.recorded.item7")}</li>
          <li>{t("privacy-policy.4-collection.recorded.item8")}</li>
        </ul>

        <p>{t("privacy-policy.4-collection.list.intro")}:</p>
        <ul className="list--no-bullets">
          <li>{t("privacy-policy.4-collection.list.item1")}</li>
          <li>{t("privacy-policy.4-collection.list.item2")}</li>
          <li>{t("privacy-policy.4-collection.list.item3")}</li>
          <li>{t("privacy-policy.4-collection.list.item4")}</li>
        </ul>

        <p>{t("privacy-policy.4-collection.desc")}</p>

        <Heading level="h2" smallDecorationDown>
          {t("privacy-policy.5-possibility-of-contact.title")}
        </Heading>

        <p>{t("privacy-policy.5-possibility-of-contact.desc")}</p>

        <Heading level="h2" smallDecorationDown>
          {t("privacy-policy.6-routine.title")}
        </Heading>

        <p>{t("privacy-policy.6-routine.desc")}</p>

        <Heading level="h2" smallDecorationDown>
          {t("privacy-policy.7-rights.title")}
        </Heading>

        <p>{t("privacy-policy.7-rights.a.title")}</p>
        <p>{t("privacy-policy.7-rights.a.desc")}</p>

        <p>{t("privacy-policy.7-rights.b.title")}</p>
        <p>{t("privacy-policy.7-rights.b.list.intro")}</p>
        <ul className="list--no-bullets">
          <li>{t("privacy-policy.7-rights.b.list.item1")}</li>
          <li>{t("privacy-policy.7-rights.b.list.item2")}</li>
          <li>{t("privacy-policy.7-rights.b.list.item3")}</li>
          <li>{t("privacy-policy.7-rights.b.list.item4")}</li>
          <li>{t("privacy-policy.7-rights.b.list.item5")}</li>
          <li>{t("privacy-policy.7-rights.b.list.item6")}</li>
          <li>{t("privacy-policy.7-rights.b.list.item7")}</li>
          <li>{t("privacy-policy.7-rights.b.list.item8")}</li>
        </ul>
        <p>{t("privacy-policy.7-rights.b.text1")}</p>
        <p>{t("privacy-policy.7-rights.b.text2")}</p>


        <p>{t("privacy-policy.7-rights.c.title")}</p>
        <p>{t("privacy-policy.7-rights.c.text1")}</p>
        <p>{t("privacy-policy.7-rights.c.text2")}</p>


        <p>{t("privacy-policy.7-rights.d.title")}</p>
        <p>{t("privacy-policy.7-rights.d.list.intro")}</p>
        <ul className="list--no-bullets">
          <li>{t("privacy-policy.7-rights.d.list.item1")}</li>
          <li>{t("privacy-policy.7-rights.d.list.item2")}</li>
          <li>{t("privacy-policy.7-rights.d.list.item3")}</li>
          <li>{t("privacy-policy.7-rights.d.list.item4")}</li>
          <li>{t("privacy-policy.7-rights.d.list.item5")}</li>
          <li>{t("privacy-policy.7-rights.d.list.item6")}</li>
          <li>{t("privacy-policy.7-rights.d.list.item7")}</li>
          <li>{t("privacy-policy.7-rights.d.list.item8")}</li>
        </ul>
        <p>{t("privacy-policy.7-rights.d.text1")}</p>
        <p>{t("privacy-policy.7-rights.d.text2")}</p>

        <p>{t("privacy-policy.7-rights.e.title")}</p>
        <p>{t("privacy-policy.7-rights.e.list.intro")}</p>
        <ul className="list--no-bullets">
          <li>{t("privacy-policy.7-rights.e.list.item1")}</li>
          <li>{t("privacy-policy.7-rights.e.list.item2")}</li>
          <li>{t("privacy-policy.7-rights.e.list.item3")}</li>
          <li>{t("privacy-policy.7-rights.e.list.item4")}</li>
        </ul>
        <p>{t("privacy-policy.7-rights.e.text1")}</p>


        <p>{t("privacy-policy.7-rights.f.title")}</p>
        <p>{t("privacy-policy.7-rights.f.text1")}</p>
        <p>{t("privacy-policy.7-rights.f.text2")}</p>
        <p>{t("privacy-policy.7-rights.f.text3")}</p>

        <p>{t("privacy-policy.7-rights.g.title")}</p>
        <p>{t("privacy-policy.7-rights.g.text1")}</p>
        <p>{t("privacy-policy.7-rights.g.text2")}</p>
        <p>{t("privacy-policy.7-rights.g.text3")}</p>
        <p>{t("privacy-policy.7-rights.g.text4")}</p>
        <p>{t("privacy-policy.7-rights.g.text5")}</p>

        <p>{t("privacy-policy.7-rights.h.title")}</p>
        <p>{t("privacy-policy.7-rights.h.text1")}</p>
        <p>{t("privacy-policy.7-rights.h.text2")}</p>
        <p>{t("privacy-policy.7-rights.h.text3")}</p>


        <p>{t("privacy-policy.7-rights.i.title")}</p>
        <p>{t("privacy-policy.7-rights.i.text1")}</p>
        <p>{t("privacy-policy.7-rights.i.text2")}</p>

        <Heading level="h2" smallDecorationDown>
          {t("privacy-policy.8-data-protection.title")}
        </Heading>

        <p>{t("privacy-policy.8-data-protection.desc")}</p>

        <Heading level="h2" smallDecorationDown>
          {t("privacy-policy.9-legal-basis.title")}
        </Heading>

        <p>{t("privacy-policy.9-legal-basis.desc")}</p>

        <Heading level="h2" smallDecorationDown>
          {t("privacy-policy.10-legitimate-interests.title")}
        </Heading>

        <p>{t("privacy-policy.10-legitimate-interests.desc")}</p>

        <Heading level="h2" smallDecorationDown>
          {t("privacy-policy.11-duration.title")}
        </Heading>

        <p>{t("privacy-policy.11-duration.desc")}</p>

        <Heading level="h2" smallDecorationDown>
          {t("privacy-policy.12-legal-provisions.title")}
        </Heading>

        <p>{t("privacy-policy.12-legal-provisions.desc")}</p>

        <Heading level="h2" smallDecorationDown>
          {t("privacy-policy.13-existance.title")}
        </Heading>

        <p>{t("privacy-policy.13-existance.text1")}</p>
        <p>{t("privacy-policy.13-existance.text2")}</p>

        <p>{t("privacy-policy.status")}</p>




      </Content>
    </Layout>
  );
};

export default PrivacyPolicy;

import React, { useEffect, useRef } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import './heading.scss';
import classNames from 'classnames';


export const Heading = ({
  level,
  children,
  white,
  card,
  revealAnimation,
  decorationDown,
  centered,
  centeredMobile,
  smallDecorationDown,
  newsCard,
  simple,
}) => {

  const Element = `${level}`;
  const headingRef = useRef();
  const gsapQuery = gsap.utils.selector(headingRef);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    let animations = [];

    animations.push(
      gsap.to(headingRef.current, {
        duration: 1,
        ease: 'none',
        scrollTrigger: {
          trigger: headingRef.current,
          start: '50% bottom',
          toggleActions: 'play none none none',
          toggleClass: 'animated',
          once: true,
        }
      })
    );

    return () => {
      animations.forEach((animation) => animation.kill());
    };

  }, []);

  return (
    <Element className={classNames("heading", level, {
      "heading--decorated": decorationDown,
      "heading--white": white,
      "heading--card": card,
      "heading--centered": centered,
      "heading--centered-mobile": centeredMobile,
      "reveal-text": revealAnimation,
      "heading--small-decoration": smallDecorationDown,
      "heading--news_card": newsCard,
      "heading--simple": simple
    })} ref={headingRef}>
      {children}
    </Element>
  );
};

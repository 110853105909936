import React from "react";
import { Heading } from "../Heading/Heading";
import "./fold.scss";
import classNames from "classnames";
import { ArrowRight } from "react-feather";

const Fold = ({ title, btnText, btnLink, centered }) => {
  return (
    <div
      className={classNames("fold", {
        "fold--centered": centered,
      })}
    >
      <div className="container">
        <div className="gr-12 no-gutter@md">
          <div className="fold__holder">
            <Heading level="h1"
            decorationDown
            centered
            >
              {title}
            </Heading>
            {btnText && (
              <a href={btnLink} className="btn btn--special">
                <span className="btn__text">{btnText}</span>
                <div className="btn__icon">
                  <ArrowRight size="24" />
                </div>
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Fold;
